import React, { useEffect } from "react";
import "./ItConsulting.css";
import LandingPage from "../../comp/LandingPage/LandingPage";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import CardList from "../../comp/CardList/CardList";
import TextSet4 from "../../comp/TextSet4/TextSet4";
import TextSet6 from "../../comp/TextSet6/TextSet6";
import TextSet7 from "../../comp/TextSet7/TextSet7";
import Line from "../../comp/Line/Line";
import {
  IndustryExpertConsulting,
  ITConsultingLanding,
  ItSecurity,
  DataAnylatic,
  IctArchitecture,
  OpratingSystem,
  BossGivingInstryctions,
  EcommerceCamping,
  ApplicationDevelopment,
  Industry_Expertise_Counslting,
  Business_Consulting,
  Application_Consulting,
  Hardware_Consulting,
  ERP_Consulting,
  security_Consulting,
  Cost_Efficient,
  Full_Guidance,
  Security_Conscious,
  Statergy_Formulation,
  Assesment,
  Solution_Building,
  Ecommerce_B2B,
  IT_infrastructure,
  Data_Security,
  Data_Storage,
  Appropriate_SoftwareHardware,
  User_Training,
  Network_Solution,
  Disaster_Recovery,
  Interpreting_organisation,
  Architectures_technologies,
  Creating_migrating,
  Strategy_formulation,
  Latest_Applications,
  Assessment_evaluation,
  Solution_Recovery_ICON,
  Analytics_implementation,
  Upgrade_systems,
  Operations_support,
  Personalisation,
  Cost_Efficiency,
  Reliable_Security,

} from '../../assests/images'
export default function ItConsulting({ setActive, title }) {

  useEffect(function () {
    document.title = title;
    setActive('Service')
  }, [setActive, title])

  const consultingData = [
    {
      heading: "Industry Expertise",
      icon: Industry_Expertise_Counslting,
    },

    {
      heading: "E-Business Consulting",
      icon: Business_Consulting,
    },

    {
      heading: "Custom Application Development Consulting",
      icon: Application_Consulting,
    },

    {
      heading: "Systems and Hardware Consulting  ",
      icon: Hardware_Consulting,
    },

    {
      heading: "ERP, CRM and HCM consulting",
      icon: ERP_Consulting,
    },

    {
      heading: "IT security consulting",
      icon: security_Consulting,
    },
  ];

  const dataForCard = [
    { img: Cost_Efficient, heading: "Cost Efficient" },
    { img: Full_Guidance, heading: "Full Guidance" },
    { img: Security_Conscious, heading: "Security Conscious" },
    { img: Statergy_Formulation, heading: "Statergy Formulation" },
    { img: Assesment, heading: "Assesment" },
    { img: Solution_Building, heading: "Solution Building" },
    { img: Ecommerce_B2B, heading: "Ecommerce and B2B" },
  ];

  const dataForHardwareConsulting = [
    { img: IT_infrastructure, heading: "IT infrastructure development" },
    { img: Data_Security, heading: "Data Security Recommendation" },
    { img: Data_Storage, heading: "Data storage planning" },
    {
      img: Appropriate_SoftwareHardware,
      heading: "Appropriate software/hardware selection",
    },
    { img: User_Training, heading: "User training" },
    { img: Network_Solution, heading: "Network Solution" },
    { img: Disaster_Recovery, heading: "Disaster recovery and management" },
  ];
  const dataForE_BusinessConsulting = [
    { img: Interpreting_organisation, heading: "Interpreting your organisation’s" },
    { img: Architectures_technologies, heading: "Architectures and emerging technologies" },
    { img: Creating_migrating, heading: "Creating a vision for migrating" },
    { img: Appropriate_SoftwareHardware, heading: "Usability Engineering" },
    { img: Latest_Applications, heading: "Latest Internet Applications" },
    { img: Strategy_formulation, heading: "Strategy formulation" },
    { img: Assessment_evaluation, heading: "Assessment and evaluation" },
    { img: Solution_Recovery_ICON, heading: "Solution-building or integration" },
    { img: Analytics_implementation, heading: "Analytics design and implementation" },
    { img: Upgrade_systems, heading: "Upgrade existing systems" },
    { img: Operations_support, heading: "Operations, maintenance and support services" },
  ];
  const dataForCustomApplication = [
    { img: Personalisation, heading: "Personalisation" },
    { img: Cost_Efficiency, heading: "Cost efficiency" },
    { img: Reliable_Security, heading: "Reliable and security-conscious" },
  ];


  return (
    <div className="consulting__container">
      <LandingPage
        img={ITConsultingLanding}
        subheading={
          "Unlock tailored IT solutions. Expert consulting for innovation, efficiency, propelling your business to sustained success in the dynamic digital landscape. Your path to excellence begins here. "
        }
        heading={
          "Customized IT Consulting Solutions for Business Excellence and Digital Transformation"
        }
        headingType={"text"}
        scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"}
      />
      <CustomiseWeb
        heading={" CUSTOMIZED WEB APPLICATION DEVELOPMENT SERVICES ENTAILING YOUR NEEDS."}
        data={consultingData}
      />
      <div className="itconsulting__wholecontent">

        <CardList
          dataForCard={dataForCard}
          /*    border={"1px solid var(--themeGray)"} */
          marginBottom={'2rem'}
        />

        <Line />
        <TextSet4
          heading={"Industry Expertise"}
          trim={200}
          mainImg={IndustryExpertConsulting}
          subheading={
            "We offer a range of professional IT consulting services, including IT administration, project management, application integration, database management, online and mobile app design, and all aspects of software development.We take the initiative to provide high-quality services that are designed to meet the needs of our clients. Our team of experts has extensive knowledge of the industry and can provide precise consulting to devise accurate strategies and authentic designs that drive the installation of flawless information technology systems for our clients.  Whether youre looking to implement new IT systems, optimize your existing infrastructure, or develop new applications, we have the skills and expertise to help you succeed."
          }
          align={"left"}
          row={'left'}
        />
        <Line />
        <TextSet4
          heading={"Systems and Hardware Consulting "}
          data={dataForHardwareConsulting}
          mainImg={OpratingSystem}
          align={"left"}
          row={'right'}
          subheading={
            "We provide comprehensive systems and hardware consulting services to help businesses improve their operations and meet their goals. Our team of experienced consultants analyzes, plans, advises and assists in developing information systems that use technology strategically to meet business requirements. We understand the company's operational and functional requirements, and we provide consulting services that align with your goals to improve business operations and understand financial planning. "
          }
        />
        <Line />

        <TextSet4
          heading={"ERP, CRM and HCM consulting"}
          trim={200}
          mainImg={BossGivingInstryctions}
          subheading={
            "We work with you to implement fresh concepts, boost output, and cut costs so that you can offer better services, which is why we offer customized enterprise resource planning (ERP), customer relations management (CRM), and human capital management (HCM) consulting services to meet your specific needs. Our experienced consultants work constantly with you to understand your business processes, identify areas for improvement, and recommend solutions that will help you achieve your goals."
          }
          align={"left"}
          row={'left'}
        />

        <Line />
        <TextSet4
          heading={"E-Business Consulting"}
          data={dataForE_BusinessConsulting}
          mainImg={EcommerceCamping}
          align={"left"}
          row={'right'}
          subheading={
            "Setting up an online business can often be a complex process, and we are here to help you solve any problems that may arise. Our advisors assist you with the hassle-free set-up of your online business processes. While we aim to establish your e-business with efficiency, productivity, and profitability, we also ensure you have the tools and support you need to succeed in the digital age. Whatever your online goals might be, we are here to help. "
          }
        />
        <Line />

        <TextSet4
          heading={"Custom Application Development Consulting"}
          trim={200}
          data={dataForCustomApplication}
          mainImg={ApplicationDevelopment}
          subheading={
            "Our bespoke software consulting service is tailored specifically by our advisors, who have over a decade of industry experience. Our software development consultants use a strategic approach to create new software applications, improve existing systems, and optimize business processes."
          }
          align={"left"}
          row={'left'}
        />
        <Line />
        <TextSet4
          heading={"ICT Architecture Consulting"}
          trim={200}
          mainImg={IctArchitecture}
          subheading={
            "Our ICT architecture consulting services encompass a variety of offerings, including software and hardware strategy development, information security strategy development, network infrastructure design and implementation, software and application design and development, data storage and management strategy development, and ICT governance and compliance."
          }
          align={"left"}
          row={'right'}
        />
        <Line />
        <TextSet4
          heading={"IT security consulting"}
          trim={200}
          mainImg={ItSecurity}
          subheading={
            "Our security consultants provide a wide range of security services to safeguard your network and data against risks that could jeopardize their confidentiality, integrity, and availability. With us as your backbone, you can expand and grow your business at an exponential rate and generate ROI at a decent pace."
          }
          align={"left"}
          row={'left'}
        />
        <Line />
        <TextSet4
          heading={"Data analytics consulting"}
          trim={200}
          mainImg={DataAnylatic}
          subheading={
            "Our data analytics services keep track of numerous phases for optimized consultation; the process starts with data collection, where we gather information from a variety of sources, and is followed by data cleaning, which removes irrelevant information. Predictive models produce new data during the data modeling step to find trends in the original data. The final phase involves visualizing data in the form of charts, graphs, etc.  Decision-making abilities are ultimately enhanced as a result."
          }
          align={"left"}
          row={'right'}
        />
      </div>
    </div>
  );
}
