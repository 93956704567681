import React, { useEffect } from "react";
import "./ItIntegration.css";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import LandingPage from "../../comp/LandingPage/LandingPage";
import TextSet4 from "../../comp/TextSet4/TextSet4";
import Line from "../../comp/Line/Line";
import {
  ItIntegrationLanding,
  SoftwareandHardware,
  ItDataIntegration,
  NetworkSecurity,
  UndrawMobileDevelopment,
  Data_Transfer,
  online_ERPCRM,
  payment_Gateways,
  GPS_tracking,
  Boost_delivery,
  Scalable,
  Safe_dependable,
  Best_possible,
  customized_solutions,
  quicker_access,
  Monitoring_application,
  Software_hardware_integration,
  API_Integration_Icon,
  Data_Integration_Icon,
  Network_Integration_Icon
} from '../../assests/images';

export default function ItIntegration({ setActive, title }) {
  useEffect(function () {
    document.title = title;
    setActive('Service')
  }, [setActive, title])

  const servicesData = [
    {
      icon: Software_hardware_integration,
      heading: 'Software and hardware integration',
    },
    {
      icon: API_Integration_Icon,
      heading: 'API Integration',
    },
    {
      icon: Data_Integration_Icon,
      heading: 'Data Integration',
    },
    {
      icon: Network_Integration_Icon,
      heading: 'Network Integration',
    },
  ];

  const dataForApiIntegration = [
    { img: Data_Transfer, heading: "Build Secure API for Data transfer" },
    {
      img: online_ERPCRM,
      heading: "Send online store data to your ERP or CRM.",
    },
    {
      img: payment_Gateways,
      heading: "Design payment gateways with safety features",
    },
    {
      img: GPS_tracking,
      heading: "GPS tracking in real-time for delivery services",
    },
    {
      img: Boost_delivery,
      heading: "Boost delivery while lowering costs.",
    },

  ];
  const dataForNetworkIntegration = [
    { img: Safe_dependable, heading: "Safe, dependable, and accessible" },
    { img: Scalable, heading: "Scalable" },
    { img: Best_possible, heading: "Best-possible results" },
    { img: customized_solutions, heading: "customized solutions" },
    { img: quicker_access, heading: "quicker information access" },
    { img: Monitoring_application, heading: "Monitoring and managing the application" },
  ];



  return (
    <div className="itintegration__container">
      <LandingPage
        img={ItIntegrationLanding}
        subheading={
          "Concoct a seamless connection of data with our integration services. Our IT integration services assist in connecting data, applications, APIs, and devices throughout your IT organization so that they can operate together seamlessly. By doing so, we help boost productivity, efficiency, and cost-cutting. With our IT integration services, you can streamline your IT operations, reduce complexity, and improve collaboration across your organization. Our solutions are designed to help you maximize your technology investments and achieve your business objectives."
        }
        heading={"IT Integration"}
        headingType={"text"}
        scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"}
      />
      <CustomiseWeb
        heading={" CUSTOMIZED WEB APPLICATION DEVELOPMENT SERVICES ENTAILING YOUR NEEDS."}
        data={servicesData}
      />
      <div style={{ marginTop: '2rem' }} className="maininnercontainer">
        <Line />
        <TextSet4
          heading={"Software and hardware integration"}
          align={"left"}
          row={'left'}
          mainImg={SoftwareandHardware}
          subheading={
            "We provide software and hardware integration services by combining software programs, databases, cloud-based applications, and computer hardware such as servers and storage devices to produce a seamless user experience. Our goal is to enable these systems to function as single-user platforms, improving efficiency and productivity for your business."
          }
        />
        <Line />
        <TextSet4
          heading={"API Integration"}
          data={dataForApiIntegration}
          align={"left"}
          row={'right'}
          mainImg={UndrawMobileDevelopment}
          subheading={
            "Gravit has years of experience integrating APIs and web services. Our IT software engineers provide API integration services that enable easy and seamless data transfer between programs running on various systems. "
          }
        />
        <Line />
        <TextSet4
          heading={"Data Integration"}
          align={"left"}
          row={'left'}
          mainImg={ItDataIntegration}
          subheading={
            " The amount of data available makes it challenging to interpret the data. Our data integration services give you the freedom to select and incorporate the appropriate data per your business requirements, resulting in a better user experience and the most effective use of the data at your disposal. Our data integration services include a variety of tasks like data extraction, transformation, loading, and validation to give our clients access to a thorough, evaluative analysis of the data. "
          }
        />

        <Line />
        <TextSet4
          heading={"Network Integration"}
          data={dataForNetworkIntegration}
          align={"left"}
          row={'right'}
          mainImg={NetworkSecurity}
          subheading={
            "Our programmers are dedicated to creating seamless network experiences for our clients by integrating various network systems. Network integration involves connecting computer systems, devices, and networks to create data-driven experiences across organizations.  Our network integration services include network design, configuration, and deployment. We also provide ongoing support and maintenance to ensure that your network is always up and running."
          }
        />
      </div>
    </div>
  );
}
