import React from "react";
import './TextSetMini.css'

export default function TextSetMini({ img, heading, subheading, flexDirection, borderBottom }) {


    return (

        <div id={heading} className="textsetmini__container" style={{ flexDirection: flexDirection ? flexDirection : 'column', borderBottom: borderBottom ? '1px solid var(--themeGray)' : '' }}>
            <div className="textsetmini__img">
                <img src={img} />
            </div>
            <div className="textsetmini__titles">
                <div className="textsetmini__heading">
                    <p className="heading2">{heading} </p>
                </div>
                <div className="textsetmini__subheading">
                    <p className="subheading">{subheading}</p>
                </div>
            </div>

        </div>


    )
}