import { useEffect } from "react";
import "./AppDevPage.css";
import LandingPage from "../../comp/LandingPage/LandingPage";
import WorkTechnology from "../../comp/WorkTechnology/WorkTechnology";
import TextSet from "../../comp/TextSet/TextSet";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import TextSet4 from "../../comp/TextSet4/TextSet4";
import Line from "../../comp/Line/Line";
import {
  AppDevLanding,
  CustomWebIcon,
  Typography,
  ColorTheory,
  WireFramePo,
  MemphisStyle,
  Ux,
  Migrating,
  Migrating2,
  Application_Modernization_App,
  Data_Migration,
  Application_Mangement,
  Application_Performance,
  Application_Maintence,
  SupportTesting,
  PermissionPerApp,
  MaintenceServices,
  UndrawMobile,
  ApplicationMoblization,
  Flutter,
  Android,
  IOS,
  React,
  Java,
  Transformation, MobaileConsulting, UIUXAPP, TestingApp, SupportServices
} from '../../assests/images';

export default function AppDevPage({ setActive, title }) {
  useEffect(function () {
    document.title = title;
    setActive('Service')
  }, [setActive, title])

  const dataForAnalytics = [
    { img: CustomWebIcon, heading: "Branding" },
    { img: Typography, heading: "Typography" },
    { img: ColorTheory, heading: "Colour Theory" },
    { img: WireFramePo, heading: "Wireframing prototyping" },
    { img: MemphisStyle, heading: "Style guiding" },
    { img: Ux, heading: "User research and personas" },
  ];

  const dataForAppTransform = [
    { img: Migrating, heading: "Platform Migration" },
    { img: Migrating2, heading: "Application Migration" },

    { img: Application_Modernization_App, heading: "Application Modernization" },
    { img: Data_Migration, heading: "Data Migration" },
    { img: Application_Mangement, heading: "Application Management" },
    { img: Application_Performance, heading: "Application Performance Tuning" },
    { img: Application_Maintence, heading: "Application Maintenance" },
  ];

  const workImgData = [
    { 'img': Flutter, 'text': 'Flutter' },
    { 'img': Android, 'text': 'Android' },
    { 'img': IOS, 'text': 'IOS' },
    { 'img': React, 'text': 'ReactNative' },
    { 'img': Java, 'text': 'JAVA' },

  ]

  const servicesData = [
    {
      icon: UIUXAPP,
      heading: 'Mobile UI/UX Design Services',
    },
    {
      icon: MobaileConsulting,
      heading: 'Mobile Consulting Services',
    },
    {
      icon: Transformation,
      heading: 'Application Transformation and Modernization Services',
    },
    {
      icon: TestingApp,
      heading: 'Software Testing and QA Services',
    },
    {
      icon: SupportServices,
      heading: 'Maintenance and Support Services',
    },
  ];

  return (
    <div className="appdevpage__container">
      <LandingPage
        img={AppDevLanding}
        subheading={`Our mobile app development services include iOS, Android and Windows.`}
        heading={
          "Make the most of our mobile development services to deliver the best user experience."
        }
        headingType={"text"}
        scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"}
      />
      <WorkTechnology
        paragraph={`  While we continue to develop, we also offer end-to-end implementation services such as user experience design, application development, and testing. Our team has over a decade of experience in these areas and continues to strive to provide cutting-edge, specialized services to assist your business's growth.`}
        data={workImgData} />

      <div className="appdev__maincon" >
        <CustomiseWeb
          heading={" CUSTOMIZED WEB APPLICATION DEVELOPMENT SERVICES ENTAILING YOUR NEEDS."}
          data={servicesData}
        />
        {/* <TextSet3 /> */}
        <Line />
        <TextSet4
          mainImg={UndrawMobile}
          heading={"Mobile UI/UX Design Services"}
          data={dataForAnalytics}
          align={"left"}
          row={'right'}
          subheading={`
             We offer a comprehensive suite of analytics and reporting services to help our clients make sense of their data and gain valuable insights into their business operations. We use tools and technologies to analyze your data and create reports in a graphical and condensed format. We can help you identify trends, track performance, and make informed decisions based on the data gathered. Whether you need customized dashboards, interactive visualizations, or detailed reports, our team can help.
             `}
        />
        <Line />
        <TextSet
          align={"left"}
          img={SupportTesting}
          rowReverse
          row={'left'}
          heading={"Mobile Consulting Services"}
          subheading={
            <>
              <br />
              As a leading mobile application development company, we provide
              our clients with confidential assistance, expert guidance, and
              intensive support throughout the consulting process, starting from
              strategy and planning to user experience, application development,
              and testing services.
              <br />
              <br />
              We provide extensive support throughout the consulting process,
              including expert guidance and intensive support, to help you
              resolve business issues, enhance ROI, and expand your business
              <br />
              <br />
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"30%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
        /> <Line />
        <TextSet4
          heading={"Application Transformation and Modernization Services"}
          mainImg={ApplicationMoblization}
          data={dataForAppTransform}
          align={"left"}
          row={'right'}
          subheading={`
             We offer a comprehensive suite of analytics and reporting services to help our clients make sense of their data and gain valuable insights into their business operations. We use tools and technologies to analyze your data and create reports in a graphical and condensed format. We can help you identify trends, track performance, and make informed decisions based on the data gathered. Whether you need customized dashboards, interactive visualizations, or detailed reports, our team can help.
             `}
        />
        <Line />
        <TextSet
          align={"left"}
          img={PermissionPerApp}
          rowReverse
          row={'left'}
          heading={"Software Testing and QA Services"}
          subheading={
            <>
              <br />
              Our services include a comprehensive range of testing and bug-fixing services to ensure that
              your software is reliable, scalable, and compatible with various devices and platforms. Our
              experts use cutting-edge tools and techniques to conduct automated and manual testing,
              performance testing, and data testing to ensure that your software meets industry standards
              and best practices. We also offer bug-fixing and solution services to address any issues that
              may arise after the software release. With our quality assurance services, you can rest assured
              that your software is of the highest quality and can provide a seamless user experience.
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"30%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
        /> <Line />
        <TextSet
          align={"left"}
          img={MaintenceServices}
          rowReverse
          row={'right'}
          heading={"Maintenance and Support Services"}
          subheading={
            <>
              <br />
              To support businesses in achieving their long-term objectives, applications must be kept up-todate with new technologies after being deployed in the market. We give your software the
              much-needed care it needs to function properly and be on par with the competition through our
              maintenance and support services. By fixing system errors and getting rid of unnecessary
              services, we provide thorough debugging and updating services that improve the scalability and
              efficiency of your company. Because they guarantee that software applications remain
              dependable, secure, and up-to-date and that users are supported and satisfied, maintenance
              and support services are crucial.
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"30%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
        /> <Line />

      </div>
    </div>
  );
}
