import React from "react";
import './Popular.css'
import { Salesforce, AppDevNoBg, CloudDevNoBg, MachineDevNoBg, EcommerceDevNoBg, CyberDevNoBg } from '../../assests/images'
export default function Popular() {


    const data = [
        {
            'title': 'Salesforce: ', 'text': ` We offer Salesforce development services and assist companies with the implementation of the sales cloud, service cloud, marketing cloud, and other custom development services.`,
            'img': Salesforce
        }, {
            'title': 'IT Consulting', 'text': 'Immerse in our app development services—precision coding, sleek design, and tailored functionality. Elevate your brand with applications that transcend expectations.',
            'img': AppDevNoBg
        }, {
            'title': '	Mobile application development', 'text': `With our 20+ years of experience in mobile application development services, we aspire to be a part of your growth through our innovative and tailored mobile application development services. `,
            'img': CloudDevNoBg
        },
        {
            'title': 'Website Application Development', 'text': `Immerse in our app development services—precision coding, sleek design, and tailored functionality. Elevate your brand with applications that transcend expectations.`,
            'img': MachineDevNoBg
        },
        {
            'title': 'IT Integration', 'text': `Immerse in our app development services—precision coding, sleek design, and tailored functionality. Elevate your brand with applications that transcend expectations.`,
            'img': EcommerceDevNoBg
        },
        {
            'title': 'Team augmentation', 'text': `Hire our skilled developers, and don't let development projects hold your business back. Collaborate with and outsource our IT team, and deliver projects on time and with the desired 
        results. 
        `,
            'img': CyberDevNoBg
        }
    ]


    const PopularCard = ({ img, title, text }) => {

        return (
            <div className="popular__card">
                <div className="popular__imgcontain">
                    <img src={img}></img>
                </div>
                <p>{title}</p>
                <p>{text}</p>
            </div>
        )
    }



    return (
        <div id="services" className="popular__container maininnercontainer">
            <div className="popular__heading">
                <p className="heading1">SERVICES</p>
            </div>
            <div className="popular__list">
                {data.map((item) => {
                    return (
                        <PopularCard img={item.img} title={item.title} text={item.text} />
                    )
                })}
            </div>
        </div>
    )
}