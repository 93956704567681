import React, { useState } from "react";
import './TextSet4.css'
import { useEffect } from "react";

export default function TextSet4({ heading, subheading, data, mainImg, align, row }) {

  const [width, setWidth] = useState('')
  if (!mainImg) {
    mainImg = 'undraw_mobile_development_re_wwsn2.svg'
  }


  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWidth(width)
    };

    // Initial check
    handleResize();

    // Attach event listener for changes in screen size
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div id={heading} className="textset4__container" >
      <div className="textset4__text" style={{ justifyContent: align == 'left' ? 'flex-start' : 'flex-end', flexDirection: row == 'right' && width > 900 ? 'row-reverse' : width < 900 ? 'column' : 'row' }}>
        <div className="textset4__img">
          <img src={mainImg} />
        </div>


        <div className="textset4__inner" style={{ margin: width > 900 ? '1rem' : 0 }}>
          <div className="textset4__heading" style={{ textAlign: align == 'left' ? 'left' : 'right', marginLeft: align == 'left' ? '' : 'auto' }}>
            <p className="heading2">{heading}</p>
          </div>
          <div className="textset4__subheading" style={{ textAlign: align == 'left' ? 'left' : 'right' }}>
            <p className="subheading" style={{ marginLeft: align == 'left' ? '' : 'auto' }}>{subheading}</p>
          </div>
          <div className="textset4__cards" style={{ marginLeft: align == 'left' ? '' : 'auto' }}>
            {data?.map((item) => {
              return (
                <div className="textset4__card">
                  <img src={item.img} />
                  <p>{item.heading}</p>
                </div>
              )
            })}
          </div>
        </div>

      </div>

    </div>
  )
}