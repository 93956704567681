import React, { useContext, useState } from "react";
import './Footer2.css'
import { FotterLogo } from "../../assests/images";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../context/MyContext";
import { useScroll } from "framer-motion";

export default function Footer({ bg }) {
  const { active, setActive } = useContext(MyContext);
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  if (!bg) {
    bg = 0
  }
  const company = [
    {
      title: "About Us",
      redirect: "/aboutus",
      active: "AboutUs"
    },
    {
      title: "Career",
      redirect: "/career",
      active: "Career"
    },
    {
      title: "Contact",
      redirect: "/contact",
      active: "Contact"
    },
  ]
  const services = [
    {
      title: "App Developement",
      redirect: "/appdevelopement"
    },
    {
      title: "Web Developement",
      redirect: "/webdevelopement"
    },
    {
      title: "IT Consulting",
      redirect: "/itconsulting"
    },
    {
      title: "Salesforce",
      redirect: "/salesforce"
    },
    {
      title: "IT Integration",
      redirect: "/itintegration"
    },
    {
      title: "IT Team Augmentation",
      redirect: "/itteamaugmentation"
    },
  ]

  const pastelColors = ['#343434', '#fff', "#E3FACD", "#FADDCC", "#E0CCFA", "#CCFACE", "#CDDFFA", "#FAF8CD"]

  return (
    <div className="footer__container" /* style={{backgroundColor:pastelColors[bg]}} */>
      <div className="footer__container__Detail">
        <div className="footer__links">
          <div className="footer__link_container">
            <div className="footer__linkbox"
            //  style={{ width: "148%" }}
            >
              <div>
                <img className="footer__img__logo" src={FotterLogo} alt="logo" onClick={() => {
                  window.scroll(0, 0)
                  navigate("/")
                }} />
                <p>+91 70006 24695</p>
                <p>info@gravitinfosystems.com</p>
                <p>S7B, Second Floor, MK City Square, Sirol Road, Gwalior, Madhya Pradesh 474011</p>
              </div>

            </div>
            <div className="footer__linkbox" >
              <div>
                <div className="footer__heading">
                  Company
                </div>
                {
                  company.map((data, index) => (
                    <p key={index} onClick={() => {
                      setActive(data.active)
                      window.scroll(0, 0)
                      navigate(data.redirect)
                    }
                    }>
                      {data.title}
                    </p>
                  ))
                }
              </div>

            </div>
            <div>
              <div>
                <div className="footer__linkbox" style={{ alignItems: "flex-start", paddingLeft: "10px" }} >
                  <div className="footer__heading" >
                    Services
                  </div>
                  {
                    services.map((data, index) => (
                      <p key={index} onClick={() => {
                        window.scroll(0, 0)
                        navigate(data.redirect)
                      }
                      }>
                        {data.title}
                      </p>
                    ))
                  }

                </div>
              </div>
            </div>
          </div>


        </div>
        <div className="footer__newsletter">
          <div className="footernewsletter__text footer__heading">
            <p>Subscribe to our newsletter</p>
            <form style={{ width: "100%" }} onSubmit={() => setEmail("")}>
              <input
                type="email"
                value={email}
                required
                // pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                onChange={(e) => setEmail(e.target.value)} />
              <div className="footer__Newletter__container">
                <div className="footernewsletter__buttonset2">
                  SUBMIT
                </div>
              </div>
            </form>


          </div>
        </div>


        {/* <p>Follow us</p> */}

      </div>
      <div>
        <div className="footer__icons">
          <a href="https://www.facebook.com/GravitInfoSystems/" target="_blank"> <FaFacebookF /></a>
          <a href="https://www.linkedin.com/company-beta/13283926" target="_blank"><FaLinkedinIn /></a>
          <a href="contact@gravitinfo.com" target="_blank"><HiOutlineMail /></a>
        </div>
        <p className="copyright">© Gravit infosystem 2023</p>
      </div>
    </div >
  )
}


