import React, { useEffect, useState, useRef } from "react";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../../assests/images";
import { motion, AnimatePresence } from "framer-motion";

export default function Header({ noopacity, active, setActive }) {
  const navigate = useNavigate()
  const [screenSize, setScreenSize] = useState("");
  const [sideBar, setSideBar] = useState(false);
  const [showSideBarServices, setShowSideBarServices] = useState(false)
  let isScrollLocked = false;
  const variants = {
    hidden: { x: "200%" },
    visible: { x: 0 },

  };
  function toggleScrollLock(lock = true) {

    if (lock) {
      // Lock scroll
      document.body.style.overflow = 'hidden !important';
    } else {
      // Unlock scroll
      document.body.style.overflow = 'visible';
    }

    // Set the lock state based on the parameter
    isScrollLocked = lock;
  }

  const handleSideBar = () => {
    /*    toggleScrollLock(true) */
    setSideBar(true);
  };
  const containerRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      // Determine the screen size based on your media queries
      if (width >= 1600) {
        setScreenSize("xl");
      } else if (width >= 1439) {
        setScreenSize("lg");
      } else if (width >= 1199) {
        setScreenSize("md");
      } else if (width >= 991) {
        setScreenSize("sm");
      } else if (width >= 575) {
        setScreenSize("xs");
      } else {
        setScreenSize("xxs");
      }
    };

    // Initial check
    handleResize();

    // Attach event listener for changes in screen size
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //////////////////////////////////////////////////////*  header scroll lock */

  useEffect(() => {
    console.log('sidebar lock triggered')
    // Check if the state is true and lock the scroll
    if (sideBar) {
      console.log('sidebar true ho ')
      /*     */
      const parentElement = document.querySelector('.pagecontainer');

      if (parentElement) {
        // Apply styles to all children inside the parent element
        Array.from(parentElement.children).forEach(child => {
          child.style.height = 'max-content';
          child.style.overflow = 'hidden';
        });
      }
      document.body.style.overflow = 'hidden'
      document.body.style.height = '100vh'

    } else {
      console.log('sidebar else staement')
      // Unlock the scroll if the state is false
      /*  */
      const parentElement = document.querySelector('.pagecontainer');

      Array.from(parentElement.children).forEach(child => {
        child.style.height = 'auto';
        child.style.overflow = 'visible';
      });
      document.body.style.overflow = "visible";
    }

    // Cleanup the style when the component unmounts
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [sideBar]);

  ///////////////////////////////////////////////////////* outside click *///////////////////

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the clicked element is outside the container
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setSideBar(false);
        setShowSideBarServices(false)
      }
    };

    // Attach event listener for clicks outside the container
    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [sideBar]);

  const submenu = useRef();

  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    setVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const showSubmenu = () => {
    submenu.current.style.display = "flex";
  };

  const hideSubmenu = () => {
    submenu.current.style.display = "none";
  };
  const keepSubmenuVisible = () => {
    showSubmenu();
  };

  const hideSubmenuOnLeave = () => {
    hideSubmenu();
  };

  return (

    <div
      /* className="header__container" */ style={{
        /* display:!visible?'':'none', */ /* top: !visible ? 0 : '-80px', */ backgroundColor:
          noopacity ? "#fff" : "rgba(255, 255, 255, 0.738)",
      }}
      className={`header__container ${visible == false ? "transitioning-down" : "transitioning-up"
        }`}
    >

      <div className="header__inner">
        <div className="header__logo">
          <img src={Logo} onClick={() => {
            window.scroll(0, 0)
            navigate("/")
          }} />
        </div>
        <div className="header__link">
          <Link style={{ color: active == 'Home' ? 'var(--themeOrange)' : '' }} onClick={() => { window.scrollTo(0, 0); setActive('Home') }} to={"/"}>Home</Link>
          <Link style={{ color: active == 'Service' ? 'var(--themeOrange)' : '' }} onClick={() => { setActive('Service') }} onMouseEnter={showSubmenu} onMouseLeave={hideSubmenu}>
            Services
          </Link>
          <Link style={{ color: active == 'AboutUs' ? 'var(--themeOrange)' : '' }} onClick={() => { window.scrollTo(0, 0); setActive('AboutUs') }} to={`/aboutus`}>About us</Link>
          {/* <Link style={{ color: active == 'Portfolio' ? 'var(--themeOrange)' : '' }} onClick={() => { window.scrollTo(0, 0); setActive('Portfolio') }} to={`/portfolio`}>Portfolio</Link> */}
          <Link style={{ color: active == 'Career' ? 'var(--themeOrange)' : '' }}
            onClick={() => { window.scrollTo(0, 0); setActive('Career') }}
            to={`/career`}
          >Career</Link>
          <Link style={{ color: active == 'Contact' ? 'var(--themeOrange)' : '' }} onClick={() => { window.scrollTo(0, 0); setActive('Contact') }} to={`/contact`}>Contact</Link>
        </div>
        <AnimatePresence>
          {sideBar ? <>

            <motion.div
              className="header__sidebar"
              transition={{ ease: 'easeInOut', duration: 0.5 }}
              ref={containerRef}
              key="sidebar"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={variants}
            >
              {
                !showSideBarServices ? <>
                  <Link onClick={() => { window.scrollTo(0, 0); setSideBar(false) }} to={"/"}>Home</Link>
                  <Link onClick={() => { window.scrollTo(0, 0); setShowSideBarServices(true) }} >
                    Services
                  </Link>
                  <Link onClick={() => { window.scrollTo(0, 0); setSideBar(false) }} to={`/aboutus`}>About us</Link>
                  <Link onClick={() => { window.scrollTo(0, 0); setSideBar(false) }} to={`/portfolio`}>Portfolio</Link>
                  <Link onClick={() => { window.scrollTo(0, 0); setSideBar(false) }} to={`/contact`}> Contact</Link></> : <>

                  <Link onClick={() => { window.scrollTo(0, 0); setSideBar(false) }} to={"/appdevelopement"}>App Developement</Link>
                  <Link onClick={() => { window.scrollTo(0, 0); setSideBar(false) }} to={"/webdevelopement"}>Web Developement</Link>
                  <Link onClick={() => { window.scrollTo(0, 0); setSideBar(false) }} to={"/itconsulting"}>IT Consulting</Link>
                  <Link onClick={() => { window.scrollTo(0, 0); setSideBar(false) }} to={"/salesforce"}>Salesforce</Link>
                  <Link onClick={() => { window.scrollTo(0, 0); setSideBar(false) }} to={"/itintegration"}> IT Integration</Link>
                  <Link onClick={() => { window.scrollTo(0, 0); setSideBar(false) }} to={"/itteamaugmentation"}>IT team augmentation</Link>

                </>
              }


            </motion.div>

          </> : <></>}    </AnimatePresence>
        <svg
          onClick={handleSideBar}
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="27" height="27" rx="13.5" fill="black" />
          <path
            d="M8.35742 16.7142H18.6431"
            stroke="white"
            stroke-linecap="round"
          />
          <path
            d="M8.35742 10.2858H18.6431"
            stroke="white"
            stroke-linecap="round"
          />
          <path
            d="M8.35742 13.5H18.6431"
            stroke="white"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div
        className="header__submenu"
        ref={submenu}
        onMouseEnter={keepSubmenuVisible}
        onMouseLeave={hideSubmenuOnLeave}
      >
        <div className="submenu__links">
          <Link onClick={() => { window.scrollTo(0, 0); }} to={"/appdevelopement"}>App Developement</Link>
          <Link onClick={() => { window.scrollTo(0, 0); }} to={"/webdevelopement"}>Web Developement</Link>
          <Link onClick={() => { window.scrollTo(0, 0); }} to={"/itconsulting"}>IT Consulting</Link>
          <Link onClick={() => { window.scrollTo(0, 0); }} to={"/salesforce"}>Salesforce</Link>
          <Link onClick={() => { window.scrollTo(0, 0); }} to={"/itintegration"}> IT Integration</Link>
          <Link onClick={() => { window.scrollTo(0, 0); }} to={"/itteamaugmentation"}>IT team augmentation</Link>
        </div>
      </div>
    </div>

  );
}
