import React,{useState} from "react";
import './TextSetMini2.css'

export default function TextSetMini2({img,heading,subheading,flexDirection,borderBottom}){
   if(!width){
    var width='50%'
   }

    return(
        <div className="textsetmini2__container" style={{flexDirection:flexDirection?flexDirection:'column',borderBottom:borderBottom?'1px solid var(--themeGray)':''}}>
            <div className="textsetmini2__titles">
                <div className="textsetmini2__heading">
                    <p className="heading1">{heading} </p>
                </div>
                <div className="textsetmini2__subheading">
                   <p className="subheading">{subheading}</p>
                </div>
            </div>
        </div>
    )
}