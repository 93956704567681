import React, { useEffect, useState } from "react";
import './ContactPage.css'
import { useLocation, useNavigate } from 'react-router-dom';
import MessageUs from "../../comp/MessageUs/MessageUs";
import LandingPage from "../../comp/LandingPage/LandingPage";
import { ContactLanding, GlobalLogisticsDeliveryNetwork, Phone, Email, Location } from '../../assests/images';

export default function ContactPage({ title }) {

    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    // State to track whether scrolling has occurred
    const [hasScrolled, setHasScrolled] = useState(false);
    useEffect(function () {
        document.title = title;
    }, [title])

    useEffect(() => {
        console.log(hasScrolled, state)
        if (!hasScrolled && state?.scrolling) {
            const scroll = state.scrolling;
            const servicesElement = document.getElementById(scroll);

            if (servicesElement) {
                // Scroll into view
                servicesElement.scrollIntoView({ behavior: 'smooth' });
                setHasScrolled(true);
                navigate(location.pathname, { state: null, replace: true });
            }
        }
    }, [location.state, hasScrolled, navigate]);

    return (
        <div className="contact__container">

            <LandingPage img={ContactLanding} subheading={`We're here to provide the support and information you need. Whether you have inquiries about our services, want assistance, or seek collaboration opportunities, reach out to us, and let's start a conversation. `}
                heading={"Have Questions? We're Here to Help, Contact Us Now!"} headingType={'text'} scroll={"contact"} buttonText={'Contact Us'} />

            <div className="conatact__companydetails">
                <div className="contact__companydetails__img" /* style={{backgroundColor:'var(--pastelOrange)'}} */>
                    <img src={GlobalLogisticsDeliveryNetwork} />
                </div>
                <div className="contact__companydetails__content">
                    <p>OUR HEAD OFFICE</p>
                    <p>In case of any requirement or query, Contact us...</p>
                    <div className="contact__companydetails__detail">

                        <div className="contactdetails__icons">
                            <img src={Phone} /> <p>070006 24695</p>
                        </div>


                        <div className="contactdetails__icons">
                            <img src={Email} /> <p>contact@gravitinfo.com</p>
                        </div>


                        <div className="contactdetails__icons" >
                            <img src={Location} /> <p>S7B, Second Floor, MK City Square, Sirol Main Rd, opposite Zila Panchayat Office, Gwalior, Madhya Pradesh 474011</p>
                        </div>

                    </div>
                </div>
            </div>
            <div id="contact" className="contact__form">
                <MessageUs />
            </div>

        </div>
    )
}