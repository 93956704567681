import React, { useEffect, useState } from "react";
import './ItTeamAugmentation.css'
import LandingPage from "../../comp/LandingPage/LandingPage";
import TextSetServices from "../../comp/TextSetServices/TextSetServices";
import BottomCta from "../../comp/BottomCta/BottomCta";
import TextSetMini from "../../comp/TextSetMini/TextSetMini";
import TextSetMini2 from "../../comp/TextSetMini2/TextSetMini2";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import {
  ItTeamAugmentationLanding,
  WebDeveloper,
  FrontEndAndBackendDeveloper,
  FrontendBackend_Developers,
  ITArgu_UiuxDesigners,
  ITArgu_Project_Managers,
  ITArgu_Business_Analysts,
  ITArgu_DevOps_Engineers,
  ITArgu_Cloud_Developers,
  ITArgu_QA_Test,
  ITArgu_Web_Developers,
  ITArgu_Mobile_Developers,
  ITArgu_Product_Designers,
  UiuxDesigners,
  BydgetManagement,
  BusinessManHolding,
  FlatTireUnder,
  CLoudServer,
  QaTesterAutomation,
  Mobile,
  ProductDesigner
} from '../../assests/images';

export default function ItTeamAugmentation({ setActive, title }) {
  useEffect(function () {
    document.title = title;
    setActive('Service')
  }, [setActive, title])

  const dataForServices = [
    { 'icon': FrontendBackend_Developers, 'heading': 'Frontend and Backend Developers' },
    { 'icon': ITArgu_UiuxDesigners, 'heading': 'UI/UX Designers' },
    { 'icon': ITArgu_Project_Managers, 'heading': 'Project Managers' },
    { 'icon': ITArgu_Business_Analysts, 'heading': 'Business Analysts' },
    { 'icon': ITArgu_DevOps_Engineers, 'heading': 'DevOps Engineers' },
    { 'icon': ITArgu_Cloud_Developers, 'heading': 'Cloud Developers' },
    { 'icon': ITArgu_QA_Test, 'heading': 'QA & Test automation engineers' },
    { 'icon': ITArgu_Web_Developers, 'heading': 'Web Developers' },
    { 'icon': ITArgu_Mobile_Developers, 'heading': 'Mobile Developers' },
    { 'icon': ITArgu_Product_Designers, 'heading': 'Product Designers' },
  ]

  const alloutsorcingData = [{
    'heading': 'Frontend and Backend Developers', 'img': FrontEndAndBackendDeveloper,
    'subheading': 'Hire our front-end and back-end IT professionals to create a top-notch website experience and execution where our front-end developers enhance your overall user experience while our back-end developers are responsible for creating and maintaining application development.'
  },

  {
    'heading': 'UI/UX Designers', 'img': UiuxDesigners,
    'subheading': 'Hire our UI/UX designers to benefit from better application designs and usability that result in interactive designs that can help you keep customers.'
  },
  {
    'heading': 'Project Managers', 'img': BydgetManagement,
    'subheading': ' Hire our highly competent project managers to execute projects with excellent communication, leadership, team management, critical thinking, and risk management skills while continuously lowering costs and guaranteeing on-time delivery. '
  },
  {
    'heading': 'Business Analysts', 'img': BusinessManHolding,
    'subheading': 'Hire one of our business analysts, who will assist you in establishing your desired business goals by thoroughly comprehending your objectives, contributing their specialized knowledge, and possessing the experience required to meet business demands.'
  },
  {
    'heading': 'DevOps Engineers', 'img': FlatTireUnder,
    'subheading': 'Hire our qualified DevOps engineers to help with the timely development and upkeep of your software. DevOps engineers work to continuously integrate the development and operations cycles by creating and implementing automation processes, creating and managing infrastructure, installing monitoring systems, and more.'
  },
  {
    'heading': 'Cloud Developers', 'img': CLoudServer,
    'subheading': 'Hire our cloud developers and create, deploy, and maintain cloud-based applications and services using our team of qualified cloud developers. Our cloud developers work with your databases to increase flexibility while lowering costs.'
  },
  {
    'heading': 'QA & Test automation engineers', 'img': QaTesterAutomation,
    'subheading': 'Hire our QA and test automation engineers to guarantee top-notch software creation. Before the website and applications are made available to users, our engineers find bugs and other problems that could affect their smooth operation.'
  },
  {
    'heading': 'Web Developers', 'img': WebDeveloper,
    'subheading': `Hire our web developers to design, build, and maintain your websites for you. Our programmers have experience with a range of web development frameworks, languages, and tools that support the development of your company's online presence. `
  },
  {
    'heading': 'Mobile Developers', 'img': Mobile,
    'subheading': 'Hire one of our mobile developers to design, develop, and maintain your mobile applications. They will do this while utilizing a variety of programming languages, frameworks, and tools to produce high-quality, user-friendly mobile applications for smartphones and tablets that can operate on different operating systems, including Android, iOS, and Windows. '
  }, {
    'heading': 'Product Designers', 'img': ProductDesigner,
    'subheading': "Hire our product designers, who will develop digital products specifically to satisfy your company's needs. To create innovative, practical, and visually appealing products, our product designers first research and prototype using various techniques."
  },]

  return (
    <>
      <LandingPage img={ItTeamAugmentationLanding} subheading={`Looking to hire skilled developers? Our developers have the skilled insight to execute and deliver competent and high-end results.
      `} customWidth={'70%'}
        heading={'IT team augmentation'} headingType={'text'} scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"} />
      <div className="maininnercontainer">
        <div className="itteamaugmentation__innerdiv">
          <TextSetMini2 heading={'Introduction'} subheading={`
          Our team of developers has years of experience in various programming languages and frameworks, and they're well-versed in the latest technologies and tools.
           We are an IT outsourcing company that takes a collaborative approach and works diligently with our clients. We believe in transparent communication and strive to keep our clients informed throughout the development process.
          By outsourcing our IT services, you'll have access to a wealth of knowledge and expertise that can help you tackle even the most complex development projects. Our developers are dedicated to delivering high-quality code that's scalable, maintainable, and meets industry standards.`}
            img={'Software-Developer.svg'} flexDirection={'row'} width={'80%'} borderBottom />


          <CustomiseWeb
            heading={" Our IT outsourcing services."}
            subheading={'From implementation to configuration and customization, we offer a comprehensive range of services to ensure the success of your IT outsourcing implementation.'}
            data={dataForServices}
          />
          {/* <TextSetServices heading={'Our IT outsourcing services'} data={dataForServices}
            subheading={'From implementation to configuration and customization, we offer a comprehensive range of services to ensure the success of your IT outsourcing implementation.'} /> */}

          {/* <BottomCta paddingTop={'0rem'} paddingBottom={'3rem'} text={'Hire From us'} heading={''} borderBottom /> */}

          <div className="itteamaugmentation__alloutsourcing">
            {alloutsorcingData.map((item, index) => {
              return (
                <TextSetMini heading={item.heading} img={item.img} subheading={item.subheading} />

              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}