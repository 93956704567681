import React, { useEffect } from "react";
import "./SalesForcePage.css";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import LandingPage from "../../comp/LandingPage/LandingPage";
import TextSetServices from "../../comp/TextSetServices/TextSetServices";
import TextSet4 from "../../comp/TextSet4/TextSet4";
import Line from "../../comp/Line/Line";
import {
  SalesforceLanding,
  Salesforcecommunities,
  SalesforceImplementation,
  Salesforcelighting,
  SalesforceIntegration,
  SalesforceConsulting,
  Salesforce_Implementation_ICON,
  Salesforce_Consulting,
  Salesforce_Customisation,
  Salesforce_Communities_ICON,
  Salesforce_Migration,
  Data_visualisation,
  Integration_Salesforce_Icon,
  Communities_appearance,
  Establishing_personalizing,
  Objects_security,
  Sale_Support,
  Salesforce_Lightning_ICON,
  Lightning_applications,
  communities_Lightning,
  Support_migration,
  BusinessIntelligense,
  SALESFORCE_CONSULTING
} from '../../assests/images'
export default function SalesForce({ setActive, title }) {
  useEffect(function () {
    document.title = title;
    setActive('Service')
  }, [setActive, title])

  const dataForServices = [
    { icon: Salesforce_Implementation_ICON, heading: "Salesforce Implementation" },
    { icon: Salesforce_Consulting, heading: "Salesforce Consulting" },
    { icon: Salesforce_Customisation, heading: "Salesforce Customisation" },
    { icon: Salesforce_Communities_ICON, heading: "Salesforce Communities" },
    { icon: Salesforce_Lightning_ICON, heading: "Salesforce Lightning" },
    { icon: Salesforce_Migration, heading: "Buisness Intelligense, Salesforce data visualisation and Migration" },
    {
      icon: Data_visualisation,
      heading: "Salesforce Data Migration",
    },
    { icon: Integration_Salesforce_Icon, heading: "Integration Salesforce" },
  ];



  const dataForSalesforceCommunities = [
    {
      img: Communities_appearance,
      heading: "Communities with a corporate appearance and feel",
    },
    {
      img: Establishing_personalizing,
      heading: "Establishing and personalizing community pages",
    },
    {
      img: Objects_security,
      heading: "Objects and security at the field levelData storage planning",
    },
    { img: Sale_Support, heading: "After-Sale Support" },
  ];

  const dataForSalesforceLightning = [
    { img: Salesforce_Lightning_ICON, heading: "Salesforce Lightning Migration" },
    {
      img: Lightning_applications,
      heading: "Creation of Lightning pages, components, and applications",
    },
    {
      img: communities_Lightning,
      heading: "Setup customer communities on Lightning",
    },
    {
      img: Support_migration,
      heading: "Support for Lightning migration and development",
    },
  ];

  return (
    <div className="salesforce__container">
      <LandingPage
        img={SalesforceLanding}
        subheading={
          "Our Salesforce developers help businesses enhance their customer experience and increase efficiency through the most popular cloud-based CRM system. Until now, we have worked on numerous Salesforce projects and provided custom software development services by leveraging our professional Salesforce services. "
        }
        heading={
          "Accelerate the growth of your business with our salesforce services."
        }
        headingType={"text"}
        scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"}
      />

      <CustomiseWeb
        heading={"Our Salesforce services"}
        subheading={
          "From implementation to configuration and customization, we offer a comprehensive range of services to ensure the success of your Salesforce implementation."
        }
        data={dataForServices}
      />
      <div className='maininnercontainer'>
        {/* <TextSetServices
          heading={"Our Salesforce services"}
          data={dataForServices}
          subheading={
            "From implementation to configuration and customization, we offer a comprehensive range of services to ensure the success of your Salesforce implementation."
          }
        /> */}
        <Line />
        <TextSet4
          heading={"Salesforce Implementation"}
          trim={200}
          mainImg={SalesforceImplementation}
          subheading={
            <>
              <p>From implementation to configuration and customization, we offer a comprehensive range of services to ensure the success of your Salesforce implementation.</p>
              <br />
              <p> We offer specialized implementation and salesforce maintenance solutions for the sales cloud, service cloud, communities, and custom development services, all designed to help you streamline your processes, increase productivity, and improve customer satisfaction. Our team is dedicated to helping you maximize the potential of the Force.com platform and get the most out of Salesforce.</p>
            </>

          }
          align={"left"}
          row={'left'}
        />
        <Line />
        <TextSet4
          heading={"Salesforce Consulting"}
          trim={200}
          mainImg={SALESFORCE_CONSULTING}
          subheading={
            <>
              <p>Our Salesforce consulting professionals assist businesses and organizations with the
                Salesforce platform as part of their services. Our Salesforce consulting services are dedicated
                to helping you increase business productivity and efficiency through our business process
                automation, as well as improve your sales, customer service, and marketing efforts.</p>
              <br />
              <p>Our technology consultants provide you with expert guidance and advice on how to best utilize
                Salesforce to meet your unique business needs. Whether you're just starting with Salesforce or
                looking to optimize your existing implementation, we have the expertise to help you achieve
                your goals.
              </p>
            </>

          }
          align={"left"}
          row={'right'}
        />
        <Line />
        <TextSet4
          heading={"Salesforce Communities"}
          data={dataForSalesforceCommunities}
          align={"left"}
          row={'left'}
          mainImg={Salesforcecommunities}
          subheading={
            "Salesforce Communities gives companies the ability to build online communities where they can interact and work together with partners, clients, and other essential business components. Communities can be shaped to fit a company's culture and brand.  "
          }
        />
        <Line />
        <TextSet4
          heading={"Salesforce Customisation"}
          // data={dataForSalesforceConsulting}
          align={"left"}
          row={'right'}
          mainImg={SalesforceConsulting}
          subheading={`Our developers work with you to provide end-to-end Salesforce customization services that 
          precisely match the needs of your business, from simple changes to fields and page layouts to 
          more complicated customizations like building custom applications and workflows. With the help 
          of our services, you can be sure that your company's customizations will be put into use in a 
          way that maximizes the platform's value and supports your long-term business goals. To give 
          you more control over your business, we create email templates, workflows, approval 
          processes, and efficient custom reports and dashboards.`}
        />

        <Line />
        <TextSet4
          heading={"Salesforce Lightning"}
          data={dataForSalesforceLightning}
          align={"left"}
          row={'left'}
          mainImg={Salesforcelighting}
          subheading={
            "Lightning is a modernized version of Salesforce that is quick and easy to use and enables users to work more promptly, effectively, and productively. Our support staff offers assistance with planning and strategy deployment for your organization. We provide community portals, bespoke Lightning-ready Salesforce apps, and customizations. "
          }
        />
        <Line />
        <TextSet4
          heading={"Salesforce Data Migration"}
          // data={dataForSalesforceConsulting}
          align={"left"}
          row={'right'}
          mainImg={SalesforceConsulting}
          subheading={`We offer you secure, upright services to transfer your sensitive data methodically. Our 
          consultants will create a unique solution for your business based on their expertise and 
          experience to produce high-yielding results, in addition to assisting you with the analysis of your 
          data migration needs. Our services comply with pre-performed services in a sandbox 
          environment before the deployment of data for their production.`}
        />
        <Line />
        <TextSet4
          heading={
            "Buisness Intelligense, Salesforce data visualisation"
          }
          align={"left"}
          row={'left'}
          mainImg={BusinessIntelligense}
          subheading={
            "Businesses can cut costs by using business intelligence to analyze data, which improves decision-making, reveals novel patterns and identifies areas that need improvement. Having a thorough understanding of the data enables businesses to boost sales and services or pay attention to warning signs for the company. Our services help present the data in understandable dashboards and interactive graphs that can be used in presentations, documents, and reports. For better decision-making, businesses that use our reporting and data visualization services can use real-time monitoring and visual analytics. "
          }
        />

        <Line />
        <TextSet4
          heading={"Integration Salesforce"}
          // data={dataForSalesforceLightning}
          align={"left"}
          row={'right'}
          mainImg={SalesforceIntegration}
          subheading={`Our Salesforce integration services enable you to create a centralized user experience by connecting data and functionality from other CRM platforms. Our Salesforce integration services include the automation of end-to-end business processes, which creates an intensely designed user experience that simplifies the user's interaction with your platform. This means that your sales reps can access data through a single platform, eliminating the need to switch between different systems and improving overall efficiency.`}
        />

      </div>
    </div>
  );
}
