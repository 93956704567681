import './WorkTechnology.css'
import "react-multi-carousel/lib/styles.css";


export default function WorkTechnology({ data, paragraph }) {

  const TechCard = ({ img, text, index }) => {
    while (index > 5) {

      index = index - 5

    }
    return (
      <div className="techcard__container">
        <img src={img} />
        <p>{text}</p>
      </div>
    )
  }

  return (
    <div className="work__technology__container">
      <div className="work__technology__header">
        <p className="work__technology__heading">{paragraph}</p>

      </div>
      <div className="work__technology__content">
        {
          data.map((item, index) => {
            return (
              <TechCard img={item.img} text={item.text} index={index} />
            )
          })

        }
      </div>

    </div>
  )
}
