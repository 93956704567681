import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha';
import './MessageUs.css'
import { GrFormSubtract } from "react-icons/gr";

export default function MessageUs() {
  const [isVerified, setIsVerified] = useState(false);
  const [form, setForm] = useState({ name: "", email: "", mobile: "", purpose: "", message: "" })
  const handlesubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.post("http://192.168.29.205:3100/api/sendGravitMail", {
        Name: form.name,
        Email: form.email,
        Phone: form.mobile,
        Purpose: form.purpose,
        Message: form.message
      })

      setForm({ name: "", email: "", mobile: "", purpose: "", message: "" })
      console.log(data.message)


    } catch (err) {
      console.log("Error", err)
    }


  }
  const handleVerifyRecaptcha = (token) => {
    // This function will be called after successful verification
    if (token) {
      setIsVerified(true);
    }
  };

  return (
    <div className="message__container">
      <div className="message__heading">
        <p>MESSAGE US</p>
        <p>Fill in the information required in the form and we will get back to you soon.</p>
        <p>Your information will be kept private and confidential and will be used by Graviti only. All details provided by you will be held by Graviti and used in accordance with our Privacy Policy.</p>
      </div>
      <div className="message__form">
        <form >
          <div className="message__form_line1">
            <div className="inputbox__container">
              <label htmlFor="name">Name <span style={{ color: "red" }}>*</span></label>
              <input type="text" name="name" value={form.name} placeholder={'Enter Full name...'}
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim();
                  if (trimmedValue !== '') {
                    setForm({ ...form, name: e.target.value });
                  }
                }}
                required />
            </div >
            <div className="inputbox__container">
              <label htmlFor="number">Mobile <span style={{ color: "red" }}>*</span></label>
              <input type="text" name="number" value={form.mobile} placeholder={'Enter number...'}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[0-9]*$/.test(inputValue) && inputValue.length < 11) {
                    setForm({ ...form, mobile: inputValue });
                  }
                }} required />
            </div >

          </div>

          <div className="message__form_line1">
            <div className="inputbox__container">
              <label htmlFor="email">Email <span style={{ color: "red" }}>*</span></label>
              <input type="email" name="email" value={form.email} placeholder={'Enter Email Address...'} onChange={(e) => setForm({ ...form, email: e.target.value })} required />
            </div >
            <div className="inputbox__container">
              {/* <label htmlFor="purposes">Purpose <span style={{ color: "red" }}>*</span></label> */}
              {/* <select
                name="purpose"
                value={form.purpose}
                onChange={(e) => setForm({ ...form, purpose: e.target.value })}
                required
              >
                <option value="" disabled>Select Purpose</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
               
              </select> */}
              <p>Purpose <span style={{ color: "red" }}>*</span></p>
              <div className="dropdown__box">
                <div className="dropdown__Selected_Date">
                  {form.purpose}
                  <div className="dropdown__icons">
                    <span><GrFormSubtract /></span>
                    <span style={{ left: "8px" }}><GrFormSubtract /></span>
                  </div>
                </div>
                <div className="dropdown__options__box">
                  <h5 onClick={() => setForm({ ...form, purpose: "Enquiry" })}>Enquiry</h5>
                  <h5 onClick={() => setForm({ ...form, purpose: "Support" })}>Support</h5>
                  <h5 onClick={() => setForm({ ...form, purpose: "Partner with us" })}>Partner with us</h5>
                </div>
              </div>
            </div>

          </div>

          <div className="message__form_line1">
            <div className="inputbox__container">
              <label htmlFor="message">Message <span style={{ color: "red" }}>*</span></label>
              <input type="text" name="message" value={form.message} placeholder={'Enter your message...'}
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim();
                  if (trimmedValue !== '') {
                    setForm({ ...form, message: e.target.value });
                  }
                }} required
              />
            </div >
          </div>
          <ReCAPTCHA
            sitekey="6LeYZ0wpAAAAAEYgLXhzix1Q2aPN6LrY4tHygysu"
            // sitekey="6LfyO1gpAAAAAGIuORESAAeMSgXEGi-T0aV-DIf8"

            onChange={handleVerifyRecaptcha}
          />
          <div className="message__button">
            <button onClick={(e) => handlesubmit(e)} type="submit">SEND</button>
          </div>
        </form>
      </div>
    </div>
  )
}