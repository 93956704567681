import React from "react";
import './TextSet.css'
import { useEffect, useState } from "react";

export default function TextSet({ customText, img, row, heading, rowReverse, subheading, imgBgColor, align, height, containerMargin, button, imageContainerWidth, imgBorder, btnTitle, borderTop, borderBottom,
  textContainerWidth,
  textWidth }) {

  if (button) {

  }
  if (borderTop) {
    borderTop = '1px solid var(--themeGray)'
  }
  else {
    borderTop = ''
  }
  if (borderBottom) {
    borderBottom = '1px solid var(--themeGray)'
  }
  else {
    borderBottom = ''
  }

  if (!containerMargin) {
    var containerMargin = [2, 2, 0, 0]
  }
  imgBorder = ''

  const [width, setWidth] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWidth(width)
      // Determine the screen size based on your media queries

    };

    // Initial check
    handleResize();

    // Attach event listener for changes in screen size
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);






  return (
    <div id={heading} className="textset__container" style={{
      flexDirection: align == 'right' ? 'row-reverse' : rowReverse ? "row-reverse" : width > 900 ? 'row' : 'column' /* ,height:height */,

      flexDirection: row == 'right' && width > 900 ? 'row-reverse' : width < 900 ? 'column' : 'row',
      marginTop: width > 767 ? `${containerMargin[0]}rem` : '1rem',
      marginBottom: width > 767 ? `${containerMargin[1]}rem` : '1rem',
      borderTop: borderTop, borderBottom: borderBottom,
      alignItems: width > 900 ? 'center' : 'flex-start'
    }}


    >
      <div className="textset__img" style={{
        borderTopLeftRadius: align == 'right' ? '' : '11px',
        borderTopRightRadius: align == 'left' ? '' : '11px',
        /*    borderBottomLeftRadius:align=='right'?'':'11px', */
        backgroundColor: imgBgColor, width: (imageContainerWidth && width > 900) ? imageContainerWidth : '79%', border: imgBorder ? imgBorder : ''
      }}>
        <img src={img} />
      </div>
      <div className="textset__text" style={{ marginLeft: ((align == "right") || rowReverse) ? 0 : !width > 900 ? 0 : '0', marginRight: align == "right" ? '2%' : 0, width: (textContainerWidth && width > 900) ? textContainerWidth : '95%' }}>
        <p className="heading1" style={{ textAlign: align == 'right' ? 'right' : 'left', marginLeft: align == 'right' ? 'auto' : '', width: textWidth ? textWidth : '' }}>{heading}</p>
        <p style={{ marginLeft: align == "right" ? 'auto' : '', textAlign: align == "right" ? 'right' : 'left', width: textWidth ? textWidth : '' }}>{subheading}</p>
        {button ? <div className="textset__button" style={{ width: '100%', display: 'flex', justifyContent: align == 'left' ? 'flex-start' : 'flex-end', alignItems: 'center' }}><button style={{ marginLeft: align == 'right' ? 'auto' : '0px' }}>{btnTitle}</button></div> : <></>}
      </div>
    </div>
  )
}