import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Screens/HomePage/Home';
import ContactPage from './Screens/ContactPage/ContactPage';
import About from './Screens/AboutPage/AboutPage';
import Career from './Screens/CareerPage/CareerPage';
import WebDevPage from './Screens/WebDevPage/WebDevPage';
import Header from './comp/Header/Header';
import Footer from './comp/Footer/Footer';
import AppDevPage from './Screens/AppDevPage/AppDevPage';
import ItConsulting from './Screens/ItConsulting/ItConsulting';
import SalesForce from './Screens/SalesForcePage/SalesForcePage';
import ItIntegration from './Screens/ItIntegration/ItIntegration';
import ItTeamAugmentation from './Screens/ItTeamAugmentation/ItTeamAugmentation';
import { useContext } from 'react';
import { MyContext } from './context/MyContext';
import React from 'react';

function App() {
  const { active, setActive } = useContext(MyContext);
  const Layout = ({ children }) => {
    return (
      <div >
        <Header active={active} setActive={setActive} />
        <div className='pagecontainer'>
          {React.Children.map(children, child => {
            // Passing setActive prop to each child
            return React.cloneElement(child, { setActive });
          })}
        </div>
        <Footer />
      </div>
    );
  };


  return (
    <div>
      <Router /*basename={"/Harleen/rishab_gravit/" }*/ >
        {/* "homepage": "http://192.168.29.121/Harleen/rishab_gravit/", */}
        <Routes >
          <Route
            path="/"
            element={
              <Layout>
                <HomePage title={"Software Development Services Company | Gravit Infosystems"} />
              </Layout>
            }
          />
          <Route
            path="/contact"
            element={
              <Layout>
                <ContactPage title={"Contact Us | Gravit Infosystems"} />
              </Layout>
            }
          />
          <Route
            path="/aboutus"
            element={
              <Layout>
                <About title={"About Us | Gravit Infosystems"} />
              </Layout>
            }
          />
          <Route
            path="/career"
            element={
              <Layout>
                <Career title={"Build Career with Gravit Infosystems"} />
              </Layout>
            }
          />
          {/* <Route
            path="/portfolio"
            element={
              <Layout>
                <ProjectsPage title={""} />
              </Layout>
            }
          /> */}
          <Route
            path="/webdevelopement"
            element={
              <Layout>
                <WebDevPage title={"Website Application Development Services| Gravit Infosystems"} />
              </Layout>
            }
          />
          <Route
            path="/appdevelopement"
            element={
              <Layout>
                <AppDevPage title={"Mobile Application Development Services| Gravit Infosystems"} />
              </Layout>
            }
          />
          <Route
            path="/itconsulting"
            element={
              <Layout>
                <ItConsulting title={"IT Consulting Services| Gravit Infosystems"} />
              </Layout>
            }
          />
          <Route
            path="/salesforce"
            element={
              <Layout>
                <SalesForce title={"Salesforce Services| Gravit Infosystems"} />
              </Layout>
            }
          />

          <Route
            path="/itintegration"
            element={
              <Layout>
                <ItIntegration title={"IT Integration Services| Gravit Infosystems"} />
              </Layout>
            }
          />
          <Route
            path="/itteamaugmentation"
            element={
              <Layout>
                <ItTeamAugmentation title={"Team Augmentation Services| Gravit Infosystems"} />
              </Layout>
            }
          />
        </Routes>
      </Router >
    </div >
  );
}

export default App;
