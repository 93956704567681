import React, { useState } from "react";
import { useEffect } from "react";
import './AboutPage.css'
import LandingPage from "../../comp/LandingPage/LandingPage";
import TextSet from "../../comp/TextSet/TextSet";
import TextSet2 from "../../comp/TextSet2/TextSet2";
import TextSetMini2 from "../../comp/TextSetMini2/TextSetMini2";
import { AboutLanding, Methodology, NewStartupIdea } from '../../assests/images';

export default function About({ title }) {
  const [width, setWidth] = useState("");

  useEffect(function () {
    document.title = title;
  }, [title])


  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      // Determine the screen size based on your media queries
      setWidth(width)
    };

    // Initial check
    handleResize();

    // Attach event listener for changes in screen size
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  return (
    <div className="aboutpage__container">

      <LandingPage img={AboutLanding} subheading={'Gravit Infosystems is fueled by a passion for software development and performs exquisitely with its coherent innovations for leading businesses and startups. '}
        heading={'About Us'} headingType={'text'} buttonText={'OUR SERVICES'} scroll={"services"} redirect={"/"} />

      <div className="maininnercontainer" style={{ display: 'flex', justifyContent: 'center', flexDirection: width > 600 ? 'row' : 'column', borderBottom: '1px solid var(--themeGray)', borderTop: '1px solid var(--themeGray)' }}>

        <div className="about__sub__maininnercontainer">
          <TextSetMini2 heading={'OUR VALUES'} subheading={`
Our values align with designing and providing quality services in the domain so that our solutions and strategies help your business and goals in your current and future endeavours." 
`} img={""} flexDirection={'row'} />

          <TextSetMini2 heading={'WHO ARE WE?'} subheading={`Gravit Infosystems is your trusted partner in software development and business process strategies. Since our company's inception in 2017, we have been providing services and innovating in new ways. Our years of experience and passion for what we do have led us to create exceptional experiences for our clients and enabled us to provide our services in India and other parts of the world for many years.`} img={'Software-Developer.svg'} flexDirection={'row'} />
        </div>

      </div>


      <div className="maininnercontainer" >
        <TextSet2
          img={NewStartupIdea}
        />
        <TextSet
          align={'left'}
          img={Methodology}
          heading={'OUR METHODOLOGIES'}
          subheading={`Software development is a continuous and dynamic process, which is why our developers and consultants use the Agile methodology to plan, create, and implement software services for our clients. Agile software development allows us to be adaptable to constantly changing business needs while we continuously evolve our services and ensure that we deliver quality-based software solutions to meet the needs of our clients now and in the future.

  We provide constant support to our clients throughout the software development process, simultaneously offering engaging, effective, and high-quality services with continually shifting business needs and providing the best services designed to satisfy unique business needs.`}
          imgBgColor={'transparent'}
          imgBorder={'1px solid var(--themeGray)'}
          // button
          btnTitle={'EXPLORE OUR PRODUCTS'}
          containerMargin={[2, 4, 0, 0]}
          borderTop
          borderBottom
          textWidth={"100%"}
        />
      </div>

    </div>
  )
}