import React from "react";
import './CardList.css'



export default function CardList({dataForCard,border,marginBottom}){
    
  if(!marginBottom){
    marginBottom=0
  }

    return(
        <div className="cardlist__container" style={{marginBottom:marginBottom}} >
          {dataForCard.map((item)=>{
            return(
                <div className="cardlistitem" style={{border:border?border:'none'}}>
                    <img src={item.img}/>
                    <p>{item.heading}</p>
                </div>
            )
          })}
        </div>
    )
}