import React from "react";
import "./Technology.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Technology({ data, heading }) {


  const TechCard = ({ img, text, index }) => {
    return (
      <div className="techcard__container">
        <img src={img} alt={text} />
        <p>{text}</p>
      </div>
    );
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 5
    }
  };



  return (
    <div className="technology__container">
      <div className="technology__header">
        <p className="technology__heading heading1">{heading}</p>
      </div>
      <div className="technology__content">
        <Carousel
          autoPlay={true}
          autoPlaySpeed={1000}
          infinite={true}
          responsive={responsive}
          arrows={false}
          customTransition="transform 300ms ease-in-out"
        >
          {data.map((item, index) => (
            <TechCard key={index} img={item.img} text={item.text} />
          ))}
        </Carousel>
      </div>
    </div>
  );
}