import React from "react";
import './TextSet2.css'

export default function TextSet2({ img, imgBgColor, imgBorder }) {
  return (
    <div className="textset2__container">
      <div className="textset2__imgtext" >
        <div className="textset2__img" style={{ backgroundColor: imgBgColor ? imgBgColor : '', border: imgBorder ? imgBorder : '' }}>
          <img src={img} />
        </div>
        <div className="textset2__minheading">
          <div className="textset2__sheading">
            <p className="heading4">how are we different</p>
          </div>
          <div className="textset2__ssubheading">
            <p className="subheading4">Our distinctive method of fusing industry knowledge with a personalized touch produces exceptional results that surpass expectations.</p>
          </div>
        </div>
      </div>
      <div className="textset2__text">
        <div className="textset2__item">
          <p>A dedicated team </p>
          <p>A dedicated team of professionals with years of expertise in providing complete end-to-end execution, which includes planning, designing, innovating, developing, releasing, and maintaining software for our customers across industry verticals and geographies.</p>
        </div>
        <div className="textset2__item">
          <p>For more than 20 years, </p>
          <p>we have continuously improved ourselves while offering development solutions to some of the most prestigious and well-known companies in the industry. </p>
        </div>
        <div className="textset2__item">
          <p>Innovators </p>
          <p>Innovators in the development of mobile apps, websites, and the cloud.</p>
        </div>
        <div className="textset2__item">
          <p>Professional development experience </p>
          <p>Professional development experience providing end-to-end software solutions to a variety of industries around the world</p>
        </div>
        <div className="textset2__item">
          <p>Providing software services to small and medium-sized businesses </p>
          <p>Providing software services to small and medium-sized businesses through the development of web and mobile applications.</p>
        </div>
        {/* <div className="textset2__item">
                  <p>EXCELLENCE</p>
                  <p>We strive to be the best; To delight our clients, exceed expectations, and provide effective solutions.</p>
                </div> */}

      </div>

    </div>
  )
}



