import { useEffect } from "react";
import "./WebDevPage.css";
import CardList from "../../comp/CardList/CardList";
import TextSet from "../../comp/TextSet/TextSet";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import BottomCta from "../../comp/BottomCta/BottomCta";
import LandingPage from "../../comp/LandingPage/LandingPage";
import TextSet4 from "../../comp/TextSet4/TextSet4";
import Line from "../../comp/Line/Line";
import WorkTechnology from "../../comp/WorkTechnology/WorkTechnology";
import {
  WebDevPageLanding,
  AnaylaticAndReporting,
  ItSecurity,
  FullStackWebDeveloper,
  WebsiteApplicationIntegration,
  ECommerceWebDevelopment,
  WebSupportAndMaintantanance,
  UnDrawDone,
  React,
  Angular,
  Salesforce,
  Html,
  Css,
  JavaScript,
  Node,
  Java,
  Php,
  Laravel,
  Codeigniter,
  Website_analytics,
  Customised_reporting,
  Heat_Mapping,
  AB_testing,
  Report_design,
  Predictive_analysis,
  Executive_Dashboards,
  Collaborate,
  FullStackDeveloperServices,
  WebItSecurity,
  AnalyticsReporting,
  ECommerceWeb,
  WebSupportMaintenance,
  CustomwebDev,
  EnterprisewebDev,
  WebsiteMaintananceDev,
  BusinessApp,
  EcommerceWebsite,
  Portfoliowebsite,
} from '../../assests/images';

export default function WebDevPage({ setActive, title }) {
  useEffect(function () {
    document.title = title;
    setActive('Service')
  }, [setActive, title])

  const dataForCard = [
    { img: CustomwebDev, heading: "Custom Web Developement" },
    { img: EnterprisewebDev, heading: "EnterPrise Web Developement" },
    { img: WebsiteMaintananceDev, heading: "Website Maintanance" },
    { img: BusinessApp, heading: "Any Buisness App" },
    { img: EcommerceWebsite, heading: "Ecommerce and B2B" },
    { img: Portfoliowebsite, heading: "Portfolio Website" },
  ];

  const dataforcustomisweb = [
    {
      icon: Collaborate,
      heading: "Website application integration services",
    },
    {
      icon: FullStackDeveloperServices,
      heading: "Full-stack web development services",
    },
    {
      icon: WebItSecurity,
      heading: "IT Security",
    },
    {
      icon: AnalyticsReporting,
      heading: "Analytics and reporting services",
    },

    {
      icon: ECommerceWeb,
      heading: "E-commerce web development",
    },
    {
      icon: WebSupportMaintenance,
      heading: "Web Support and Maintenance Services",
    },
  ]

  const dataForAnalytics = [
    { img: Website_analytics, heading: "Website analytics" },
    { img: Customised_reporting, heading: "Customised reporting" },
    { img: Heat_Mapping, heading: "Heat Mapping" },
    { img: AB_testing, heading: "A/B testing" },
    { img: Report_design, heading: "Report design" },
    { img: Predictive_analysis, heading: "Predictive analysis" },
    { img: Executive_Dashboards, heading: "Executive Dashboards" },
  ];

  const workImgData = [
    { 'img': React, 'text': 'React' },
    { 'img': Angular, 'text': 'Angular.' },
    { 'img': Salesforce, 'text': 'Salesforce' },
    { 'img': Html, 'text': 'HTML' },
    { 'img': Css, 'text': 'CSS' },
    { 'img': JavaScript, 'text': 'JavaScript' },
    { 'img': Node, 'text': 'Node' },
    { 'img': Java, 'text': 'JAVA' },
    { 'img': Php, 'text': 'PHP' },
    { 'img': Laravel, 'text': 'Laravel' },
    { 'img': Codeigniter, 'text': 'Codeigniter' },

  ]

  return (
    <div className="webdev__container">
      <LandingPage
        img={WebDevPageLanding}
        subheading={
          "We can help you develop a new web application from scratch or upgrade an existing one by using proven development methodologies that also help us deliver web applications that are reliable, scalable, and secure for use."
        }
        heading={"Gravit crafts swift, friendly, stylish web apps."}
        headingType={"text"}
        scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"}
      />

      <WorkTechnology
        paragraph={``}
        data={workImgData} />
      <Line />
      <div className="webdev__wholecontent">

        <div className="cardlist__main__container">
          <CardList dataForCard={dataForCard} />

        </div>
        <Line />
        <TextSet
          align={"left"}
          row={'left'}
          img={UnDrawDone}
          heading={"Set the bar for website quality in the digital era. "}
          subheading={
            <>
              <br />
              At Gravit, we specialize in creating web applications that are
              fast, user-friendly, and visually appealing. Our team constantly
              stays up-to-date with the latest industry standards and trends to
              ensure that your web application is modern, efficient, and
              compatible across all devices and platforms.
              <br />
              <br />
              We can help you develop a new web application from scratch or
              upgrade an existing one by using proven development methodologies
              that also help us deliver web applications that are reliable,
              scalable, and secure for use.
              <br />
              <br />
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"80%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
          rowReverse
        />
        <Line />
        <CustomiseWeb
          heading={" CUSTOMIZED WEB APPLICATION DEVELOPMENT SERVICES ENTAILING YOUR NEEDS."}
          data={dataforcustomisweb}
        />
        <Line />
        <TextSet4
          heading={"Website application integration services"}
          trim={200}
          mainImg={WebsiteApplicationIntegration}
          subheading={
            <>
              <br />
              Our team can help you integrate your web applications with other systems, eliminating data silos and providing a unified view of your business operations. Our integration services also help to improve data accuracy, reduce manual data entry, and increase the overall efficiency of your business processes.
              <br />
              <br />
              Our integration solutions are planned precisely for your unique business needs, ensuring that you get the most out of your investment. Our team constantly works with you, identifies your integration requirements, designs a solution that meets those requirements and provides ongoing support to ensure that your integration is working seamlessly across all systems.
              <br />
              <br />
            </>
          }
          align={"left"}
          row={'left'}
        />
        <Line />
        <TextSet4
          heading={"Analytics and reporting services"}
          data={dataForAnalytics}
          mainImg={AnaylaticAndReporting}
          align={"left"}
          row={'right'}
          subheading={`
             We offer a comprehensive suite of analytics and reporting services to help our clients make sense of their data and gain valuable insights into their business operations. We use tools and technologies to analyze your data and create reports in a graphical and condensed format. We can help you identify trends, track performance, and make informed decisions based on the data gathered. Whether you need customized dashboards, interactive visualizations, or detailed reports, our team can help.
             `}
        />
        <Line />
        <TextSet
          align={"left"}
          row={'left'}
          img={WebSupportAndMaintantanance}
          heading={"Web Support and Maintenance Services"}
          subheading={
            <>
              <br />
              A good website requires periodic upkeep to ensure it runs smoothly
              and keeps up with current standards. Our expert team is dedicated
              to ensuring the best website performance for your company. We work
              tirelessly to ensure that all links are functional, that we update
              content frequently, address problems promptly, back up crucial
              files, and accelerate website performance.
              <br />
              <br />
              We provide ongoing support and maintenance to ensure that your
              website is always up-to-date and in good working order. We are
              here to assist you with routine updates, troubleshooting, and
              performance optimization. With our assistance, you can concentrate
              on running your business while we handle your website.
              <br />
              <br />
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"50%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
          rowReverse
        />
        <Line />
        <TextSet4
          heading={"Full-stack web development services"}
          mainImg={FullStackWebDeveloper}
          trim={200}
          subheading={
            <>
              <br />
              With our expertise in front-end development, back-end development, database management, service configuration deployment, and testing, we provide end-to-end solutions that meet our clients' needs.
              <br />
              <br />
              Our team is dedicated to designing intuitive, dynamic, interactive, and engaging interfaces to make your web experience high-performance and scalable. We understand the importance of user-friendly interfaces that are easy to navigate and interact with, and we strive to create experiences that keep your audience engaged and satisfied.
              <br />
              <br />
            </>
          }
          align={"left"}
          row={'right'}
        />
        <Line />
        <TextSet
          rowReverse
          align={"left"}
          row={'left'}
          img={ECommerceWebDevelopment}
          heading={"E-commerce web development"}

          subheading={
            <>
              At our company, we create the technical elements of your website,
              including payment gateways, user-friendly designs, and more. With
              our help, you can give your users the experience they want and
              connect with your customers to generate sales. Our team has
              experience in developing secure web portals, online stores, B2B
              and B2C e-commerce services, specialized software as a service
              (SaaS) solutions, as well as booking and ticketing platforms.
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"30%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
        />
        <Line />
        <TextSet4
          heading={"IT Security"}
          mainImg={ItSecurity}
          trim={200}
          subheading={
            <>
              Our web developers ensure that they provide complete protection and secure software
              development technologies against threats, hacking, and malware attacks. We understand the
              risks that businesses face regarding data breaches, and we work tirelessly to ensure that our
              clients' applications are secure. From firewalls and intrusion detection systems to multi-factor
              authentication and encryption, we offer a comprehensive range of security solutions to keep
              your data safe. By prioritizing security in our development process, we can help you protect your
              valuable data and safeguard your business against potential threats.
              <br />
              <br />
              <ul>
                <li>Executing tests for SQL injection.</li>
                <li>XSS.</li>
                <li>Remote code execution as part of a routine vulnerability assessment.</li>
                <li>Running a manual security test for important applications as part of penetration testing
                  helps to thwart sophisticated threats.</li>
                <li>Installation of an SSL certificate creates HTTPS websites, which are a more secure
                  version of a website and are typically trusted by users because their traffic is encrypted
                  using SSL/TLS.</li>
                <li>We conduct a malware scan on your website and eliminate any malicious files that might
                  have crept into your system undetected.</li>
              </ul>

            </>}
          align={"left"}
          row={'right'}
        />
        <Line />
        {/* <BottomCta text={"Contact us"} /> */}
      </div>
    </div>
  );
}
