import React, { useState, useEffect, useRef } from "react";
import './LandingPage.css'
import { motion as m } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function LandingPage({ img, subhead, scroll, redirect, subheading, heading, buttonText, headingType, customWidth, paragraph }) {

  if (!customWidth) {
    customWidth = '100%'
  }


  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const navigate = useNavigate();

  const handleResize = () => {
    if (containerRef.current) {
      const { clientWidth, clientHeight } = containerRef.current;
      setContainerWidth(clientWidth);
      setContainerHeight(clientHeight);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Initial measurement
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    if (redirect) {
      navigate(redirect, { state: { scrolling: scroll } });
    } else {
      const servicesElement = document.getElementById(scroll);
      if (servicesElement) {
        servicesElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className="landingpage__main__container">
      <div className="landingpage__container">
        <div className="landingpage__text">
          <m.div className="landingpage__toptext"
            initial={{ scale: 1, opacity: 0, translateY: '10%' }}
            animate={{ opacity: 1, scale: 1, translateY: '1%' }}
            transition={{ duration: 1.1 }}

          >
            {headingType == 'img' ? <>
              <img src={heading} ></img></> :
              <p className="landingpage__heading">{heading}</p>}
            {subhead && <p className="landingpage__subhead" >{subhead}</p>}
            <p className="landingpage__subheading">{subheading}</p>
          </m.div>


          <div className="buttonset__container">
            {/* <a href={`${redirect ? redirect : scroll}`} style={{ listStyle: "none", textDecoration: "none" }}>
              <p className="buttonsettext" style={{ color: '#fff', fontFamily: "'Signika Negative', 'sans-serif'" }}>{buttonText}</p>
            </a> */}
            <a onClick={handleClick} style={{ listStyle: "none", textDecoration: "none" }}>
              <p className="buttonsettext">{buttonText}</p>
            </a>
          </div>

        </div>


        <div className="landingpage__images" ref={containerRef}>

          <img src={img} /* className="mask1" */ style={{
            width: "100%",
            height: "100%"
          }}></img>


        </div>
      </div>
    </div>

  )
}