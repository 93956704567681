import React, { useState } from "react";
const MyContext = React.createContext();


function MyProvider(props) {
    const [active, setActive] = useState('')
    return (
        <MyContext.Provider value={{
            active,
            setActive
        }}>
            {props.children}
        </MyContext.Provider>
    );
}
export { MyContext, MyProvider };
