import React from 'react'
import './Listbox.css'
export default function Listbox() {

    const strangthsData = [
        "On-time delivery", " Industry Expertise", "Agile Delivery", "Impeccable quality"
    ]
    const IndustriesData = [
        "Aviation messaging",
        "Consumer Products",
        "Financial Services",
        "Healthcare",
        "Industrial Goods and Services",
        "Media",
        "Mining and Construction",
        "Private Equity",
        "Retail",
        "Social and Public Sector",
        "Technology",
        "Telecommunications",
        "Utilities and Alternative Energy",
        "Oil and gas",
        "Logistics and Automotive",
        "E-learning"
    ]
    return (
        <div className='Listbox__container'>
            <div className='listbox__header'>
               
                <div className='Listbox__mini__container'>
                    <h3 className='heading2'>Articulating Innovation through Software Development</h3>
                    <p className='subheading'>
                        At Gravit, we understand that every business needs a unique standard that keeps up with
                        constant business demands and produces faster and more user-friendly results for its clients.
                        We are a leading software development company that creates a timeline, calculates the budget,
                        develops a suitable workflow, and properly deploys your services so that you can work
                        efficiently and provide a seamless user experience to you and your clients
                    </p>
                </div>

            </div>
            {/* <div className='listbox__secound__container'>
                <div className='listbox__sub__header'>
                    <h3 className='heading2'>Industries we serve</h3>
                    <p className='subheading'>Our dedication to providing cutting-edge and competent expertise has enabled us to assist
                        clients across a wide range of industries</p>
                    <ul>
                        {IndustriesData.map((item, index) => (
                            <div style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                                <p style={{ width: "5px", height: "5px", backgroundColor: "#000", borderRadius: "50%" }}></p>
                                <li key={index}>{item}</li>
                            </div>


                        ))}
                    </ul>
                </div>
                <div className='listbox__sub__header'>
                    <h3 className='heading2'>Our Strengths</h3>
                    <p className='subheading'>Our commitment to innovation, excellence and unmatched customer
                        service</p>
                    <ul>
                        {strangthsData.map((item, index) => (
                            <div style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                                <p style={{ width: "5px", height: "5px", backgroundColor: "#000", borderRadius: "50%" }}></p>
                                <li key={index}>{item}</li>
                            </div>
                        ))}

                    </ul>
                </div>

            </div > */}
        </div >
    )
}
