import React from "react";
import './Reviews1.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { UserPic, Review } from '../../assests/images.js'


export default function Reviews() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const clients = [{
    img: UserPic,
    name: 'Jane Doe',
    reviewtext: `Collaborating with Gravit Infosystem Pvt Ltd was an outstanding experience. Their dedication to quality and unwavering support significantly contributed to the triumph of our project. Gravit Infosystem stands out for its dependability, effectiveness, and consistently delivering outstanding results.`
  },
  {
    img: UserPic,
    name: 'Smith',
    reviewtext: `We had a fantastic experience partnering with Gravit Infosystem Pvt Ltd. Their team's dedication, expertise, and consistent support were pivotal to the success of our project. Gravit Infosystem is a name you can trust for their reliability, efficiency, and ability to consistently deliver exceptional results.`
  },
  {
    img: UserPic,
    name: 'David Anderson',
    reviewtext: `Gravit Infosystem Pvt Ltd proved to be an invaluable partner for our project. Their proactive approach, coupled with a high level of expertise, ensured the success of our endeavor. Gravit Infosystem is a reliable and efficient choice for anyone seeking exceptional outcomes and a positive collaboration experience.`
  },
  {
    img: UserPic,
    name: 'Emily White',
    reviewtext: `Exceptional experience with Gravit Infosystem. Their team's dedication to excellence and ongoing support have been integral to the success of our project. Gravit Infosystem is our trusted partner for reliability, efficiency, and consistently delivering top-notch results. A pleasure to work with.`
  },
  {
    img: UserPic,
    name: 'Michael Roberts',
    reviewtext: `Working with Gravit was a game-changer for our project! Their unwavering commitment to excellence and remarkable support have been the driving force behind our success. Gravit Infosystem is synonymous with reliability, efficiency, and achieving exceptional results. We look forward to future collaborations.`
  },
  ]


  const ClientsCard = ({ item }) => {

    return (
      <div className="reviews__item">
        <div className="review__userpic">
          <img src={item.img} />
          <svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.266 0.378C8.77267 0.378 10.0533 0.905333 11.108 1.96C12.1627 3.01467 12.69 4.48367 12.69 6.367C12.69 8.627 12.2003 10.8493 11.221 13.034C10.317 15.294 9.074 17.2903 7.492 19.023C5.98533 20.831 4.25267 22.2247 2.294 23.204L0.26 19.927C2.068 18.7217 3.57467 17.4033 4.78 15.972C5.98533 14.616 6.62567 12.921 6.701 10.887C5.797 10.8117 4.96833 10.548 4.215 10.096C3.46167 9.56867 2.859 8.92833 2.407 8.175C2.03033 7.34633 1.842 6.48 1.842 5.576C1.842 4.59667 2.10567 3.73033 2.633 2.977C3.16033 2.22367 3.83833 1.621 4.667 1.169C5.49567 0.641666 6.362 0.378 7.266 0.378ZM25.3637 0.378C26.8703 0.378 28.151 0.905333 29.2057 1.96C30.2603 3.01467 30.7877 4.48367 30.7877 6.367C30.7877 8.627 30.298 10.8493 29.3187 13.034C28.4147 15.294 27.1717 17.2903 25.5897 19.023C24.083 20.831 22.3503 22.2247 20.3917 23.204L18.3577 19.927C20.1657 18.7217 21.6723 17.4033 22.8777 15.972C24.083 14.616 24.7233 12.921 24.7987 10.887C23.8947 10.8117 23.066 10.548 22.3127 10.096C21.5593 9.56867 20.9567 8.92833 20.5047 8.175C20.128 7.34633 19.9397 6.48 19.9397 5.576C19.9397 4.59667 20.2033 3.73033 20.7307 2.977C21.258 2.22367 21.936 1.621 22.7647 1.169C23.5933 0.641666 24.4597 0.378 25.3637 0.378Z" fill="#121212" />
          </svg>

        </div>
        <div className="review__text">
          <p>{item.name}</p>
          <p>{item.reviewtext}
          </p>
        </div>

      </div>
    )
  }


  return (
    <div className="review__main__container">
      <p className="heading1">WHAT DOES OUR CLIENTS SAYS</p>
      <div className="reviews__container">
        <div className="reviews__img">
          <img src={Review} />
        </div>
        <div className="reviews__content">
          {/* <p className="heading1">LETS SEE</p> */}
          {/* <p className="heading1">WHAT DOES OUR CLIENTS SAYS</p> */}
          <div className="review__scroll" >
            <Carousel
              responsive={responsive}
              keyBoardControl={true}
              showDots={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              itemClass="carousel-item-padding-40-px"
              infinite={true}
              arrows={false}

            >
              {
                clients.map((item) => (
                  <ClientsCard item={item} />
                )
                )
              }
            </Carousel>
          </div>

        </div>
      </div>
    </div>

  )
}